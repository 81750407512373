
.contenttile {
    flex: 1 0 auto;
    background: color(white);
    padding-bottom: sp(5);

    @include media-breakpoint-up(md) {
        padding: sp(5);
        box-shadow: 0 rem(4) rem(8) rgba(0, 0, 0, 0.1);
    }

    &__title {
        margin: sp(5) 0 0;
        font-size: rem(24);
        font-style: fst(italic);
        font-weight: fw(normal);
        line-height: lh(small);
    }

    &__text {
        padding: 0 sp(1);
        margin: sp(5) 0;
        font-family: ff(secondary);
        font-weight: fw(light);
        font-style: fst(italic);
        line-height: lh(medium);
    }

    &__cta {
        font-weight: fw(light);
        font-style: fst(italic);
    }
}
