//--------------------------------------------------------------------------------------------------------
// Colors
// e.g.
// Color:            .h-clr-black, .h-clr-sm-black, .h-clr-md-black, .h-clr-lg-black, .h-clr-xl-black
// Background Color: .h-bg-black, .h-bg-sm-black, .h-bg-md-black, .h-bg-lg-black, .h-bg-xl-black
// Border Color:     .h-bdc-black, .h-bdc-sm-black, .h-bdc-md-black, .h-bdc-lg-black, .h-bdc-xl-black
//--------------------------------------------------------------------------------------------------------

@each $colorName, $colorValue in $color-palette {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            .h-clr#{$infix}-#{"#{$colorName}"} {
                color: color($colorName, "base") !important;
            }

            .h-bg#{$infix}-#{"#{$colorName}"} {
                background-color: color($colorName, "base") !important;
            }

            .h-bdc#{$infix}-#{"#{$colorName}"} {
                border-color: color($colorName, "base") !important;
            }

            @each $spectrumName, $spectrumValue in $colorValue {
                .h-clr#{$infix}-#{"#{$colorName}"}-#{$spectrumName} {
                    color: color($colorName, $spectrumName) !important;
                }

                .h-bg#{$infix}-#{"#{$colorName}"}-#{$spectrumName} {
                    background-color: color($colorName, $spectrumName) !important;
                }

                .h-bdc#{$infix}-#{"#{$colorName}"}-#{$spectrumName} {
                    border-color: color($colorName, $spectrumName) !important;
                }
            }
        }
    }
}
