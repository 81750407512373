.btn {
    font-size: rem(20);
    line-height: 1;
    position: relative;
    display: inline-block;
    padding: rem(21) rem(10);
    overflow: hidden;
    color: colorType(text);
    text-decoration: none;
    border: 1px solid color(black, primary);
    background-color: color(white);
    border-radius: 0;
    text-align: center;
    transition: background-color map-get($transiton-types, base);

    @include media-breakpoint-up(md) {
        padding: rem(32) rem(20);
        font-size: rem(24);
        min-width: rem(226);

        &:hover {
            background-color: color(grey, null, dark);
        }
    }
}
