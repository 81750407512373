.bnrproduct {
    &__tiles {
        display: flex;
        overflow-x: auto;
        column-gap: sp(5);
        padding: 0 sp(3) sp(5) 0;
        -webkit-overflow-scrolling: touch;

        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }

    &__tile {
        min-width: rem(300);
        width: rem(300);
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            position: absolute;
            z-index: map-get($z-index-types, contenttile);
            width: rem(380);
        }

        @include media-breakpoint-up(xl) {
            width: rem(445);
        }
    }

    &__lefttile {
        $left-shift: sp(7);
        $left-shift-lg: sp(7);
        $left-shift-xl: rem(-10);
        $left-shift-xxl: rem(-90);
        $left-shift-xxxl: rem(-160);

        @include media-breakpoint-up(md) {
            bottom: rem(60);
            left: $left-shift;
        }

        @include media-breakpoint-up(lg) {
            left: $left-shift-lg;
        }

        @include media-breakpoint-up(xl) {
            left: $left-shift-xl;
            bottom: rem(165);
        }

        @include media-breakpoint-up(xxl) {
            left: $left-shift-xxl;
        }

        @include media-breakpoint-up(xxxl) {
            left: $left-shift-xxxl;
        }
    }

    &__righttile {
        $right-shift: sp(7);
        $right-shift-lg: sp(7);
        $right-shift-xl: rem(-10);
        $right-shift-xxl: rem(-90);
        $right-shift-xxxl: rem(-160);

        transition: transform map-get($transiton-types, base);

        @include media-breakpoint-up(md) {
            top: rem(60);
            right: $right-shift;
        }

        @include media-breakpoint-up(lg) {
            right: $right-shift-lg;
        }

        @include media-breakpoint-up(xl) {
            right: $right-shift-xl;
            top: rem(160);
        }

        @include media-breakpoint-up(xxl) {
            right: $right-shift-xxl;
        }

        @include media-breakpoint-up(xxxl) {
            right: $right-shift-xxxl;
        }

        &.m-stuck {
            position: fixed;
            top: $header-height-desktop;

            @include media-breakpoint-up(md) {
                right: calc(((100vw - #{map-get($container-max-widths, md) - $grid-gutter-width-px}) / 2) + #{$right-shift});
                top: $header-height-desktop;
            }

            @include media-breakpoint-up(lg) {
                right: calc(((100vw - #{map-get($container-max-widths, lg) - $grid-gutter-width-px}) / 2) + #{$right-shift-lg});
            }

            @include media-breakpoint-up(xl) {
                right: calc(((100vw - #{map-get($container-max-widths, xl) - $grid-gutter-width-px}) / 2) + #{$right-shift-xl});
                top: $header-height-desktop;
            }

            @include media-breakpoint-up(xxl) {
                right: calc(((100vw - #{map-get($container-max-widths, xl) - $grid-gutter-width-px}) / 2) + $right-shift-xxl);
            }

            @include media-breakpoint-up(xxxl) {
                right: calc(((100vw - #{map-get($container-max-widths, xl) - $grid-gutter-width-px}) / 2) + $right-shift-xxxl);
            }
        }
    }
}
