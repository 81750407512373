@use 'sass:math';

//----------------------------------------------------------------------
// Global
//----------------------------------------------------------------------

$base-html-font-size      : 16px;

//----------------------------------------------------------------------
// Fonts
//----------------------------------------------------------------------

$ff-primary-name: 'Merriweather';
$ff-primary: $ff-primary-name, "Helvetica Neue", "Arial", sans-serif;
$ff-secondary-name: 'OpenSans';
$ff-secondary: $ff-secondary-name, "Helvetica Neue", "Arial", sans-serif;

$font-famlies: (
    primary: $ff-primary,
    secondary: $ff-secondary
);

$font-weights: (
    normal: 400,
    light: 300
);

$font-sizes:   (10, 12, 14, 16, 18, 20, 24);
$line-heights: (
    fit: 1,
    small: 1.25,
    medium: 1.36,
    large: 1.5
);

$font-styles: (
    normal: normal,
    italic: italic
);

//----------------------------------------------------------------------
// Color Palette
//----------------------------------------------------------------------

$color-palette: (
    black         : (base: #000000, primary: #232323),
    white         : (base: #ffffff),
    grey          : (base: #cccccc),
    none          : (base: transparent)
);

$color-opacity: (
    original: 1,
    light: 0.7,
    medium: 0.5,
    small: 0.4,
    dark: 0.3
);

//----------------------------------------------------------------------
// Z-Index
//----------------------------------------------------------------------

$z-indexes: (
    negative: -1,
    min: 1,
    small: 2,
    medium: 5,
    large: 10,
    larger: 15,
    max: 1000
);

$z-index-types: (
    header         : map-get($z-indexes, larger),
    contenttile    : map-get($z-indexes, medium)
);

//----------------------------------------------------------------------
// Aspect Ratio
//----------------------------------------------------------------------

$aspect-ratios: (
    #{1x1}: math.div(math.round(math.div(1, 1) * 100), 100),
    #{4x5}: math.div(math.round(math.div(4, 5) * 100), 100),
    #{16x9}: math.div(math.round(math.div(16, 9) * 100), 100),
    #{video_portrait}: math.div(math.round(math.div(1212, 2298) * 100), 100),
    #{logo}: math.div(math.round(math.div(150, 57) * 100), 100)
);

//----------------------------------------------------------------------
// Override Bootstrap spacing variables
//----------------------------------------------------------------------

$spacer: 10px;  // Set the base spacing unit
$spacers: (
  0: 0,
  1: $spacer * 0.25, // 2.5px
  2: $spacer * 0.5, // 5px
  3: $spacer, // 10px
  4: $spacer * 1.5, // 15px
  5: $spacer * 2, // 20px
  6: $spacer * 2.5, //  25px
  7: $spacer * 3, // 30px
  8: $spacer * 3.5, // 35px
  9: $spacer * 4, // 40px
  10: $spacer * 4.5, // 45px,
  11: $spacer * 5 // 50px
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1236px
) !default;

@import "./functions";

$grid-gutter-width-px: 24px;
$grid-gutter-width: rem($grid-gutter-width-px) !default;

//----------------------------------------------------------------------
// Color Types
//----------------------------------------------------------------------

$color-types: (
    text         : color(black, primary)
);

$transiton-types: (
    base         : 0.3s ease
);

$header-height: rem(60);
$header-height-desktop: rem(97);
