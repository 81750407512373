//----------------------------------------------------------------------
// Font Face
//----------------------------------------------------------------------

@include font-face($ff-primary-name, '../fonts/Merriweather-Regular', 400, normal);
@include font-face($ff-primary-name, '../fonts/Merriweather-Italic', 400, italic);
@include font-face($ff-primary-name, '../fonts/Merriweather-LightItalic', 300, italic);

@include font-face($ff-secondary-name, '../fonts/OpenSans-Regular', 400, normal);
@include font-face($ff-secondary-name, '../fonts/OpenSans-Italic', 400, italic);
@include font-face($ff-secondary-name, '../fonts/OpenSansLight-Italic', 300, italic);

//----------------------------------------------------------------------
// Override Bootstrap CSS variables
//----------------------------------------------------------------------

:root {
    --bs-font-sans-serif: #{$ff-primary};
    --bs-body-color: #{colorType(text)};
}
