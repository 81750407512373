.page {
    padding-top: $header-height;

    @include media-breakpoint-up(md) {
        padding-top: $header-height-desktop
    }

    &__main {
        padding-bottom: sp(11);
    }
}

a {
    text-decoration: underline;
    color: colorType(text);

    &:hover {
        text-decoration: none;
    }
}
