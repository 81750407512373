//--------------------------------------------------------------------------------------------------------
// Aspect Ratio
// e.g. .h-ar-1x1, .h-ar-4x5, .h-ar-16x9
//--------------------------------------------------------------------------------------------------------

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        @each $name, $value in $aspect-ratios {
            .h-ratio#{$infix}-#{$name} {
                aspect-ratio: $value !important;
            }
        }
    }
}

.h-sr-only {
    @include sr-only;
}
