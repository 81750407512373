

.pageheader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    transition: transform map-get($transiton-types, base);
    z-index: map-get($z-index-types, header);

    &__logo {
        width: rem(105);

        @include media-breakpoint-up(md) {
            width: rem(150);
        }
    }
}

.pageheader-bottomoffset-reached[data-scrolldir="down"] {
    .pageheader {
        transform: translateY(-100%);
    }

    .m-stuck {
        &.m-pageheader-sticky-shift {
            @include media-breakpoint-up(md) {
                transform: translateY(-$header-height-desktop);
            }
        }
    }
}
