//--------------------------------------------------------------------------------------------------------
// Font families
// e.g. .h-ff-primary, .h-ff-secondary
//--------------------------------------------------------------------------------------------------------

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        @each $name, $value in $font-famlies {
            .h-ff#{$infix}-#{$name} {
                font-family: $value !important;
            }
        }
    }
}

//--------------------------------------------------------------------------------------------------------
// Font weights
// e.g. .h-fw-light, .h-fw-normal
//--------------------------------------------------------------------------------------------------------

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        @each $name, $value in $font-weights {
            .h-fw#{$infix}-#{$name} {
                font-weight: $value !important;
            }
        }
    }
}

//--------------------------------------------------------------------------------------------------------
// Font sizes
// e.g. .h-fs-12, .h-fs-24
//--------------------------------------------------------------------------------------------------------

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        @each $value in $font-sizes {
            .h-fs#{$infix}-#{$value} {
                font-size: rem($value) !important;
            }
        }
    }
}

//--------------------------------------------------------------------------------------------------------
// Line heights
// e.g. .h-lh-fit, .h-lh-small, .h-lh-medium, .h-lh-large
//--------------------------------------------------------------------------------------------------------

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        @each $name, $value in $line-heights {
            .h-lh#{$infix}-#{$name} {
                line-height:  $value !important;
            }
        }
    }
}

//--------------------------------------------------------------------------------------------------------
// Font styles
// e.g. .h-fst-normal, .h-fst-italic
//--------------------------------------------------------------------------------------------------------

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        @each $name, $value in $font-styles {
            .h-fst#{$infix}-#{$name} {
                font-style: $value !important;
            }
        }
    }
}
