//----------------------------------------------------------------------
// Extend bootstrap percentage width utility classes by breakpoints
// e.g.: .w-50, .w-sm-50, .w-md-50, .w-lg-50, .w-xl-50
//----------------------------------------------------------------------

$percentage-widths: ('auto', 25, 50, 75, 100);

@each $bp in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($bp, $grid-breakpoints);

    @if ($infix != '') {
        @include media-breakpoint-up($bp) {
            @each $width in $percentage-widths {
                $width-class: if(type-of($width) == 'string', $width, $width);
                $width-value: if(type-of($width) == 'string', $width, $width + '%');

                .w#{$infix}-#{$width-class} {
                    width: unquote($width-value) !important;
                }
            }
        }
    }
}
